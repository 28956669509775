<template>
  <div :class="`medication-item ${wrapClassName}`">
    <MedicationDetails :item="item" />
    <div class="action">
      <div class="medication-status">
        <v-btn-toggle>
          <v-btn
            :class="`status-option ${item.status === 'active' && !isEditingStatus ? 'status-active' : ''}`"
            height="100%"
            @click="onChangeStatus('active')"
          >
            Active
          </v-btn>
          <v-btn
            :class="`status-option ${(item.status === 'differently' || isEditingStatus) && 'status-active'}`"
            height="100%"
            @click="onEdit"
          >
            Differently
          </v-btn>
          <v-btn
            :class="`status-option ${item.status === 'not-taken' && !isEditingStatus && 'status-active'}`"
            height="100%"
            @click="onChangeStatus('not-taken')"
          >
            Not taking
          </v-btn>
        </v-btn-toggle>
      </div>
      <div v-if="isEditingStatus" class="edit-wrap">
        <Textarea
          :value="additionalInstruction"
          fieldName="additionalInstruction"
          placeholder="Enter additional instructions"
          @change="onFieldChange"
        />
        <div class="edit-actions">
          <v-btn color="primary" depressed @click="onSaveEditDifferently"> Save</v-btn>
          <v-btn color="primaryLight1" depressed @click="onCancel"> Cancel</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { MedicationApi } from "@/api/medication";
import Textarea from "@/components/uikit/Textarea";
import MedicationDetails from "@/components/VisitNotes/medicationReconsiliation/MedicationDetails";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { usePatientStore } from "@/pinia-store/patient";

export default {
  name: "MedicationItem",
  components: { MedicationDetails, Textarea },
  props: {
    wrapClassName: {
      type: String,
      default() {
        return "";
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isEditingStatus: false,
      additionalInstructions: "",
    };
  },
  computed: {
    ...mapState(usePatientStore, ["patient"]),
    statuses() {
      return [
        { label: "Taking", value: "active" },
        { label: "Differently", value: "unknown" },
        { label: "Not taking", value: "not-taken" },
      ];
    },
  },
  methods: {
    ...mapActions(usePatientStore, ["getSelfReportedMedication"]),
    ...mapActions(useAutoSaveStore, ["endSave", "startSave"]),
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    onEdit(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isEditingStatus = !this.isEditingStatus;
    },
    onCancel() {
      this.isEditingStatus = false;
    },
    async onChangeStatus(status) {
      this.startSave();
      await MedicationApi.patch(this.item.id, {
        ...this.item,
        informationSource: "patient",
        informationSourceId: this.patient.id,
        status: status,
      });
      await this.getSelfReportedMedication();
      this.endSave();
    },
    async onSaveEditDifferently() {
      this.startSave();
      await MedicationApi.patch(this.item.id, {
        ...this.item,
        informationSource: "patient",
        informationSourceId: this.patient.id,
        additionalInstruction: this.additionalInstruction,
        status: "differently",
      });
      this.isEditingStatus = false;
      await this.getSelfReportedMedication();
      this.endSave();
    },
  },
};
</script>

<style lang="scss" scoped>
.medication-item {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 15px 10px;
  width: 100%;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid var(--v-primarylight2-base);

  .action {
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  .edit-wrap {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;

    .edit-actions {
      gap: 16px;
      display: flex;
    }

    .textarea {
      width: 100%;
    }

    .v-input__slot {
      margin-top: 0 !important;
    }

    .add-medication {
      background-color: #fff !important;
      flex-basis: 200px;
    }
  }

  .name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Dark Gray - 1 */

    color: #33343e;

    opacity: 0.9;
  }

  .edit {
    display: flex;
    gap: 12px;

    button {
      text-transform: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      .v-btn__content {
        padding: 8px 11px;
      }
    }
  }
}

.medication-status {
  .status-active {
    color: #2196f3;
  }

  .status-option {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: white !important;
    text-transform: none;
  }
}
</style>
